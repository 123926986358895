import { useState, createContext, useContext } from 'react';
import { BANNERS } from '~/constants/messages';

const Checkout10Context = createContext();

const Checkout10Provider = ({ children }) => {
  const [values, setValues] = useState({
    choice: {},
    textField: {},
    isBoolChecked: false,
    isCountryInvalid: false,
    datePicker: {
      dateTimeFrameStart: '',
      dateTimeFrameEnd: '',
      dayBeforeMinStartDate: '',
      dayAfterMaxStartDate: '',
    },
  });

  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(BANNERS.ERROR);
  const [isLoading, setIsLoading] = useState(false);
  const [addressErrors, setAddressErrors] = useState({});
  const [preconditionError, setPreconditionError] = useState(false);
  const [addressDialogIsOpen, setAddressDialogIsOpen] = useState(false);
  const [checkout10InputsDisabled, setCheckout10InputsDisabled] = useState(
    false,
  );

  const clearError = (id, key) => {
    const currentErrors = { ...errors };

    if (currentErrors[id]) {
      key ? delete currentErrors[id][key] : delete currentErrors[id];
    }

    setErrors(currentErrors);
  };

  const value = {
    values,
    setValues,
    errors,
    preconditionError,
    setPreconditionError,
    setErrors,
    message,
    setMessage,
    messageType,
    setMessageType,
    isLoading,
    setIsLoading,
    addressErrors,
    setAddressErrors,
    addressDialogIsOpen,
    setAddressDialogIsOpen,
    clearError,
    checkout10InputsDisabled,
    setCheckout10InputsDisabled,
  };

  return (
    <Checkout10Context.Provider value={value}>
      {children}
    </Checkout10Context.Provider>
  );
};

const useCheckout10Context = () => {
  const context = useContext(Checkout10Context);

  if (context === undefined) {
    throw new Error(
      'useCheckout10Context must be used within a Checkout10Provider',
    );
  }

  return context;
};

export { Checkout10Provider, useCheckout10Context };
