import apiCall from './apiCall';
import sanitiseUrlPath from './sanitiseUrlPath';

/* api/offers endpoint can be used by id or slugName
we need to encode this value in case of slugName to allow '/' */
const getOffer = ({ id, onSuccess, onError }) =>
  apiCall(
    `${API_OFFER_SERVICE}/offers/${encodeURIComponent(
      sanitiseUrlPath(id),
    )}?brand=${MH_BRAND}`,
    {
      method: 'GET',
      onSuccess: response => {
        if (onSuccess) {
          return onSuccess(response);
        }
        return response;
      },
      onError: error => {
        if (onError) {
          return onError(error);
        }
        return error;
      },
    },
  );

export default getOffer;
