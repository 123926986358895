import InlineLabel from './InlineLabel';
import AbsoluteLabel from './AbsoluteLabel';

const Label = ({ type, text }) =>
  type === 'absolute' ? (
    <AbsoluteLabel text={text} />
  ) : (
    <InlineLabel text={text} />
  );

export default Label;
