import { getI18n, useTranslation } from 'react-i18next';
import {
  Box,
  Heading,
  Paragraph,
  Caption,
  Divider,
  LinkText,
  Flex,
} from '@mediahuis/chameleon-react';
import { colorBlackBase } from '@mediahuis/chameleon-theme-wl';
import styled from 'styled-components';
import Wrapper from '../Wrapper';
import DidomiPreferences from '../didomi/DidomiPreferences';
import { Bold } from '../Bold';
import language from './language';

const Link = styled(LinkText)`
  color: ${colorBlackBase} !important;
  text-decoration: none;
  &&:hover {
    text-decoration: none;
  }
`;

const FooterDesktop = () => {
  useTranslation();
  const { language: lang } = getI18n();
  const translation = language[lang];

  return (
    <Box bgColor="colorGrey10" pt={7} pb={7}>
      <Wrapper>
        <Heading mb={4} level={3}>
          {translation.footerTitle}
        </Heading>
        <Paragraph mb={4} maxWidth="400px">
          {translation.footerContactPhoneDesktop}
        </Paragraph>
        <Paragraph>
          <Bold>
            <a href={`tel:${translation.customerServicePhoneNumber}`}>
              {translation.customerServicePhoneNumber}
            </a>
          </Bold>
        </Paragraph>
        <Caption mb={5} color="colorGreyBase">
          {translation.footerContactPhoneClosingHourDesktop}
        </Caption>
        <Divider />
        <Flex justifyContent="space-between">
          <Box>
            <Caption mt={5}>
              <DidomiPreferences />
            </Caption>
            <Caption mt={3}>
              <Link
                href={translation.privacyPolicyUrl}
                target="_blank"
                data-testid="button-privacy-policy"
              >
                {translation.privacyPolicy}
              </Link>
            </Caption>
            {MH_BRAND === 'dl' && (
              <Caption mt={3}>
                <Link
                  href={translation.termsLink}
                  target="_blank"
                  data-testid="button-algemene-voorwaarden"
                >
                  {translation.terms}
                </Link>
              </Caption>
            )}
          </Box>
          {MH_BRAND === 'dl' && (
            <Box textAlign="right">
              <Caption mt={5}>{translation.name}</Caption>
              <Caption mt={3}>{translation.addressLine1}</Caption>
              <Caption mt={3}>{translation.addressLine2}</Caption>
              <Caption mt={3}>{translation.kvkNumber}</Caption>
              <Caption mt={3}>{translation.btwNumber}</Caption>
            </Box>
          )}
        </Flex>
      </Wrapper>
    </Box>
  );
};

export default FooterDesktop;
