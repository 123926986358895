/* eslint-disable no-console */
import { getSearchParams } from '~/utils/constants';
import { checkUtmParameters } from '~/utils/tracking/utmParameters';
import {
  UTM_ARTICLE_ID,
  MH_APPLICATION_NAME,
  IOS_NEWS_APP_APPLICATION_NAME,
} from '~/constants';

const setupCMP = ({ globalState, setGlobalState }) => {
  console.log('setupCMP', MH_ENV);

  const utmData = {};
  const trackingData = {};

  const omgRequestIdKey = 'sub_id';
  const utmDataPrefix = 'utm_';
  const queryDataPrefix = 'data_';

  const searchParams = getSearchParams();
  const referrerParams = new URLSearchParams(
    (document.referrer && document.referrer.split('?')[1]) || '',
  );

  for (const key of searchParams.keys()) {
    if (key === omgRequestIdKey) {
      utmData[key] = searchParams.get(omgRequestIdKey);
    } else if (key === UTM_ARTICLE_ID) {
      sessionStorage.setItem(UTM_ARTICLE_ID, searchParams.get(key));
    } else if (key === MH_APPLICATION_NAME) {
      const applicationName = searchParams.get(key);
      if (applicationName === IOS_NEWS_APP_APPLICATION_NAME) {
        sessionStorage.setItem(MH_APPLICATION_NAME, applicationName);
      }
    }

    if (key.startsWith(queryDataPrefix)) {
      const replacedKey = key.replace(queryDataPrefix, '');
      trackingData[replacedKey] = searchParams.get(key);
    } else if (key.startsWith(utmDataPrefix)) {
      const value = searchParams.get(key);
      utmData[key] = value;
      trackingData[key] = value;
    }
  }

  for (const key of referrerParams.keys()) {
    if (key.startsWith(utmDataPrefix)) {
      utmData[`ref_${key}`] = referrerParams.get(key);
    }
  }

  checkUtmParameters(utmData);

  // this ensures a proper functioning aboshop after 5 seconds
  // in case the consent platform is not available at the moment

  if (globalState.staticRender) {
    console.log('CMP staticRender');
    setGlobalState({ cmpLoaded: true });
    setTimeout(() => {
      setGlobalState({ cmpLoaded: true });
    }, 2000);
  } else {
    console.log('CMP setTimeout');
    setTimeout(() => {
      setGlobalState({ cmpLoaded: true });
    }, 5000);
  }

  setGlobalState({ trackingData, utmData });
};

export default setupCMP;
