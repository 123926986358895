import { useState, useContext, createContext, useEffect } from 'react';
import { VALIDATION_KEYS, CHOICE, TEXT_FIELD } from '~/constants/validations';
import { useGlobalContext } from '~/context/global';

const CheckoutContext = createContext();

const CheckoutProvider = ({ children }) => {
  const { user } = useGlobalContext();
  const DEFAULT_VALIDATIONS = {
    choiceValidation: [],
    textFieldValidation: [],
  };

  const [validations, setValidations] = useState(DEFAULT_VALIDATIONS);

  const [initiallyHasFirstName, setInitiallyHasFirstName] = useState(
    Boolean(user?.firstName),
  );
  const [initiallyHasName, setInitiallyHasName] = useState(Boolean(user?.name));
  const [paymentOptionCount, setPaymentOptionCount] = useState(0);

  const initValidations = values => {
    const mappedValidations = DEFAULT_VALIDATIONS;

    values.forEach(value => {
      const validationKey = VALIDATION_KEYS[value.type];

      switch (value.type) {
        case CHOICE:
        case TEXT_FIELD: {
          // Validations with multiple entries
          mappedValidations[validationKey].push(value);
          break;
        }
        default: {
          // Validations with a single entry
          mappedValidations[validationKey] = value;
        }
      }
    });

    setValidations(mappedValidations);
  };

  useEffect(() => {
    let counter = 0;
    const paymentIntervalOptions =
      validations.paymentValidation?.parameters?.paymentIntervalOptions;
    if (paymentIntervalOptions) {
      Object.entries(paymentIntervalOptions).forEach(interval => {
        const options = interval[1].options.length;
        counter += options;
      });
      setPaymentOptionCount(counter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validations.paymentValidation]);

  const value = {
    validations,
    initValidations,
    initiallyHasFirstName,
    initiallyHasName,
    setInitiallyHasFirstName,
    setInitiallyHasName,
    paymentOptionCount,
  };

  return (
    <CheckoutContext.Provider value={value}>
      {children}
    </CheckoutContext.Provider>
  );
};

const useCheckoutContext = () => {
  const context = useContext(CheckoutContext);

  if (context === undefined) {
    throw new Error(
      'useCheckoutContext must be used within a CheckoutProvider',
    );
  }

  return context;
};

export { CheckoutProvider, useCheckoutContext };
