/* eslint-disable import/max-dependencies */
import 'url-search-params-polyfill';
import 'antd/dist/antd.min.css';
import '@mediahuis/chameleon-reset';
import { lazy, Suspense } from 'react';
import { hot } from 'react-hot-loader/root';
import { Wizard } from 'react-albus/lib';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Flex } from '@mediahuis/chameleon-react';
import { GlobalStyle } from '~/styles';
import StaticRender from '~/components/StaticRender';
import { Didomi, TopBar, Footer } from '~/components';
import { OfferProvider } from '~/context/offer';
import { GlobalProvider } from '~/context/global';
import { InsightsProvider } from '~/context/insights';
import { CheckoutProvider } from '~/context/checkout';
import { OnlineStatusProvider } from '~/hooks/useOnlineStatus';
import PageLoader from './components/PageLoader';
import './i18n';

const Offer = lazy(() => import('~/pages/Offer'));
const Renewal = lazy(() => import('~/pages/Renewal'));
const Access = lazy(() => import('~/pages/Access'));
const NotFound = lazy(() => import('~/pages/NotFound'));
const Checkout = lazy(() => import('~/pages/Checkout'));
const Settlement = lazy(() => import('~/pages/Settlement'));
const Smart = lazy(() => import('~/pages/Smart'));
const VoucherPrint = lazy(() => import('~/pages/Voucher/VoucherPrint'));
const VoucherInput = lazy(() => import('~/pages/Voucher/VoucherInput'));

const App = ({ staticRender = false }) => (
  <Router>
    <Suspense fallback={<PageLoader />}>
      <GlobalStyle />
      <GlobalProvider staticRender={staticRender}>
        {!staticRender && <Didomi />}
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          style={{ minHeight: '100vh' }}
        >
          <OnlineStatusProvider>
            <TopBar />
            <StaticRender staticRender={staticRender}>
              <OfferProvider>
                <Switch>
                  <Route path="/404" component={NotFound} />
                  <Route path="/access" component={Access} />
                  <Route
                    path="/checkout"
                    render={({ history, match: { url } }) => (
                      <Wizard history={history} basename={url}>
                        {({ step, steps }) => (
                          <InsightsProvider>
                            <CheckoutProvider>
                              <Checkout step={step} steps={steps} />
                            </CheckoutProvider>
                          </InsightsProvider>
                        )}
                      </Wizard>
                    )}
                  />
                  <Route path="/renewal" component={Renewal} />
                  <Route path="/winback" component={Renewal} />
                  <Route path="/settlement" component={Settlement} />
                  <Route path="/smart" component={Smart} />
                  <Route path="/voucher" component={VoucherPrint} />
                  <Route path="/cadeau" component={VoucherInput} />
                  <Route path="*" component={Offer} />
                </Switch>
              </OfferProvider>
            </StaticRender>
            <Footer />
          </OnlineStatusProvider>
        </Flex>
      </GlobalProvider>
    </Suspense>
  </Router>
);

export default hot(App);
