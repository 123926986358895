import styled from 'styled-components';
import { Box, Caption, Flex as CHFlex } from '@mediahuis/chameleon-react';
import {
  colorPrimaryBase,
  colorTertiary70,
} from '@mediahuis/chameleon-theme-wl';

/* eslint-disable indent */
const Label = styled(Box)`
  position: relative;
  display: inline-block;
  border-top-left-radius: ${props =>
    props.hasMultiple && !props.iseven ? 0 : '4px'};
  border-top-right-radius: ${props =>
    props.hasMultiple && props.iseven ? 0 : '4px'};
  flex: 1;
  &:after {
    background-color: ${props =>
      props.iseven ? colorPrimaryBase : colorTertiary70};
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 4px;
    top: 0;
    left: 0;
    border-top-left-radius: ${props =>
      props.hasMultiple && !props.iseven ? 0 : '4px'};
    border-top-right-radius: ${props =>
      props.iseven && props.hasMultiple ? 0 : '4px'};
  }
`;

const Flex = styled(CHFlex)`
  position: absolute;
  right: ${props => (props.hasMultiple ? '10%' : '20%')};
  top: -23px;
  text-align: center;
  width: ${props => (props.hasMultiple ? '80%' : '60%')};
  z-index: 10;
`;

const AbsoluteLabel = ({ text }) => {
  const labels = text.split(',');
  const hasMultiple = labels.length >= 2;
  return (
    <Flex hasMultiple={hasMultiple} mt={2}>
      {labels.map((label, index) => {
        const isEven = index % 2 === 0;
        return (
          <Label
            hasMultiple={hasMultiple}
            p={2}
            key={label}
            bgColor={isEven ? 'colorPrimaryBase' : 'colorTertiary70'}
            iseven={isEven ? 1 : 0}
            data-testid="card-label"
          >
            <Caption
              level={2}
              color={isEven ? 'colorTextPrimaryBase' : 'colorWhiteBase'}
            >
              {label}
            </Caption>
          </Label>
        );
      })}
    </Flex>
  );
};

export default AbsoluteLabel;
