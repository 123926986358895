import { useEffect, useState } from 'react';
import { getI18n } from 'react-i18next';
import { Select, Loader } from '@mediahuis/chameleon-react';
import { useCheckoutContext } from '~/context/checkout';
import { getCountriesWithLanguage } from '~/utils/getCountries';
import language from './language';

const SelectCountry = ({ values, onChange, disabled = false }) => {
  const { validations } = useCheckoutContext();
  const [countries, setCountries] = useState(null);
  const { addressAreaValidation } = validations;
  const allowedCountries = addressAreaValidation?.parameters?.countries;

  const { language: lang } = getI18n();
  const translation = language[lang];

  useEffect(() => {
    getCountriesWithLanguage({
      countries: allowedCountries,
      onSuccess: response => {
        setCountries(response);
      },
    });
  }, [allowedCountries]);

  if (!countries) {
    return <Loader />;
  }

  return (
    <Select
      id="country"
      label={translation.country}
      data-testid="country"
      value={values.country}
      onChange={e => onChange(e.target.value)}
      disabled={disabled}
    >
      {countries.map(country => (
        <option key={country.IsoCode} value={country.IsoCode}>
          {country.Name}
        </option>
      ))}
    </Select>
  );
};

export default SelectCountry;
