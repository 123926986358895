const base = {
  [LANG.NL]: {
    contactCustomerService: 'Contacteer klantenservice',
    footerTitle: 'Hulp nodig?',
    footerContactPhoneDesktop:
      'Neem telefonisch contact op met onze klantenservice via het nummer',
    footerContactPhoneClosingHourDesktop: 'Neem tot 17u contact op',
    privacyPolicy: 'Privacy Policy',
    privacyPolicyUrl: 'https://www.mediahuis.be/privacy-policy',
    customerServicePhoneNumber: '03 303 30 37',
  },
  [LANG.DE]: {
    contactCustomerService: 'Kundendienst kontaktieren',
    footerTitle: 'Brauchen Sie Hilfe?',
    footerContactPhoneDesktop:
      'Kontaktieren Sie unseren Kundenservice telefonisch unter der Nummer',
    footerContactPhoneClosingHourDesktop: 'Kontaktieren Sie uns bis 17:00 Uhr',
    privacyPolicy: 'Datenschutzerklärung',
    privacyPolicyUrl: 'https://www.wort.lu/de/privacy',
    customerServicePhoneNumber: '+352/4993-9393',
  },
  [LANG.EN]: {
    contactCustomerService: 'Contact customer service',
    footerTitle: 'Need help?',
    footerContactPhoneDesktop: 'Contact our customer service using this number',
    footerContactPhoneClosingHourDesktop:
      'Contact us until 17:00 (closing time customer service)',
    privacyPolicy: 'Privacy policy',
    privacyPolicyUrl: 'https://www.wort.lu/en/privacy',
    customerServicePhoneNumber: '+352/4993-9393',
  },
  [LANG.FR]: {
    contactCustomerService: 'Contacter le service clientèle',
    footerTitle: "Besoin d'aide?",
    footerContactPhoneDesktop: 'Contactez notre service clientèle au numéro',
    footerContactPhoneClosingHourDesktop: "Contactez nous jusqu'à 17:00 heures",
    privacyPolicy: 'Politique de confidentialité',
    privacyPolicyUrl: 'https://www.wort.lu/fr/privacy',
    customerServicePhoneNumber: '+352/4993-9393',
  },
  [LANG.PT]: {
    contactCustomerService: 'Contactar o serviço de cliente',
    footerTitle: 'Precisa de ajuda?',
    footerContactPhoneDesktop:
      'Contacte o nosso serviço de apoio ao cliente através do número:',
    footerContactPhoneClosingHourDesktop: '(Dias úteis das XXXXXX às 17h00)',
    privacyPolicy: 'Política de privacidade',
    privacyPolicyUrl: 'https://www.wort.lu/pt/privacy',
    customerServicePhoneNumber: '+352/4993-9393',
  },
};

export default base;
