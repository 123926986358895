import {
  GOTO,
  ORIGIN_VITRINE,
  UTM_ARTICLE_ID,
  SUBSCRIPTION_ID,
  MULTI_BRAND,
  PAYMENT_REFERENCE,
  PERSONALISATION_CONSENT,
  PARAM_FORMULA_ID,
  CODE,
} from '~/constants';

export const personalisationConsent = new URLSearchParams(
  PERSONALISATION_CONSENT,
  window.location.search,
);

export const getSearchParams = () =>
  new URLSearchParams(window.location.search);
export const getSearchUtmArticleId = () =>
  getSearchParams().get(UTM_ARTICLE_ID);
export const getSearchGoToUrl = () => getSearchParams().get(GOTO);
export const getSearchSubscriptionId = () =>
  getSearchParams().get(SUBSCRIPTION_ID);
export const getSearchMultiBrand = () => getSearchParams().get(MULTI_BRAND);
export const getPaymentReference = () =>
  getSearchParams().get(PAYMENT_REFERENCE);
export const getOrderId = () => getSearchParams().get('order_id');

export const getOriginVitrine = () => sessionStorage.getItem(ORIGIN_VITRINE);
export const getSessionStorageUtmArticleId = () =>
  sessionStorage.getItem(UTM_ARTICLE_ID);
export const getSessionStorageGoToUrl = () => sessionStorage.getItem(GOTO);
export const getSearchFormulaId = () => getSearchParams().get(PARAM_FORMULA_ID);
export const getSearchCode = () => getSearchParams().get(CODE);
