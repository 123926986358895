import { LinkText } from '@mediahuis/chameleon-react';

const nlBase = {
  AboPolicy: () => (
    <>
      Ik heb gelezen en aanvaard de{' '}
      <LinkText
        href="https://www.mediahuis.be/nl/abonnementsvoorwaarden/"
        target="_blank"
        style={{ textDecoration: 'underline ' }}
      >
        algemene abonnementsvoorwaarden
      </LinkText>{' '}
      die onder meer voorzien in een herroepingsrecht.
    </>
  ),
};

const base = {
  [LANG.NL]: nlBase,
  [LANG.DE]: nlBase,
  [LANG.EN]: {
    AboPolicy: () => (
      <>
        I have read and accept the{' '}
        <LinkText
          href="https://www.mediahuis.be/nl/abonnementsvoorwaarden/"
          target="_blank"
          style={{ textDecoration: 'underline ' }}
        >
          general terms of subscription
        </LinkText>{' '}
        which include a right of withdrawal.
      </>
    ),
  },
  [LANG.FR]: nlBase,
  [LANG.PT]: nlBase,
};

export default base;
