import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Flex,
  Icon,
  Logo,
  Paragraph,
  Select,
} from '@mediahuis/chameleon-react';
import { colorGrey10, space2 } from '@mediahuis/chameleon-theme-wl';
import { ShieldFill } from '@mediahuis/chameleon-theme-wl/icons';
import goHome from '~/utils/goHome';
import { getI18n } from 'react-i18next';
import styled from 'styled-components';
import { useGlobalContext } from '~/context/global';
import { CHECKOUT_FLOW } from '~/constants';
import { getCheckout30, getSettlement30 } from '~/constants/checkout';
import Wrapper from '../Wrapper';
import { language } from './language';
import { GLOBALS } from '../../config';

const SelectWithouthBorder = styled(Select)`
  #language {
    border: none;
    background-color: ${colorGrey10};
    &:focus,
    &:active {
      box-shadow: none !important;
      border: none;
    }

    ${props =>
      props.disabled &&
      `
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: initial;
    `}
  }
`;

export const LOGO_VERSIONS = {
  [GLOBALS.BRANDS.GVA]: 'v2',
  [GLOBALS.BRANDS.HBVL]: 'v2',
  [GLOBALS.BRANDS.DS]: 'v2',
  [GLOBALS.BRANDS.DL]: 'v1',
  [GLOBALS.BRANDS.NB]: 'v2',
};

const TopBar = () => {
  const { language: lang } = getI18n();
  const translation = language[lang];

  const history = useHistory();

  const { setLanguage, setGlobalState } = useGlobalContext();

  const isLangSelectDisabled = () => {
    const isCheckout = window.location.pathname.includes('checkout');

    switch (true) {
      case isCheckout:
        return true;
      default:
        return false;
    }
  };

  const handleGoHome = () => {
    const isCheckout30 =
      window.location.pathname.includes(getCheckout30(lang)?.id) ||
      window.location.pathname.includes(getSettlement30(lang)?.id);

    // reset checkoutFlow to default value
    sessionStorage.removeItem(CHECKOUT_FLOW);
    setGlobalState({ checkoutFlow: null });
    goHome(history, {}, { isCheckout30 });
  };

  return (
    <Box bgColor="colorGrey10" p={0} mb={6} height={isMobile ? 9 : 10}>
      <Wrapper>
        <Flex justifyContent="space-between" alignItems="center">
          <Logo
            data-testid="button-home"
            extension="svg"
            height={isMobile ? 9 : 10}
            maxWidth={11}
            name={MH_BRAND === 'tc' ? 'brand-main' : 'brand-square-main'}
            style={{ cursor: 'pointer' }}
            onClick={handleGoHome}
          />
          <Flex alignItems="center">
            {POSSIBLE_LANGUAGES[MH_BRAND]?.length < 2 && isMobile && (
              <Box pr={isMobile ? 2 : 7}>
                <Flex alignItems="center">
                  <Icon
                    color="colorBlackBase"
                    as={ShieldFill}
                    style={{ paddingRight: space2 }}
                  />
                  <Paragraph color="colorBlackBase">
                    {translation.secureConnection}
                  </Paragraph>
                </Flex>
              </Box>
            )}
            {!isMobile && (
              <Box pr={isMobile ? 2 : 7}>
                <Flex alignItems="center">
                  <Icon
                    color="colorBlackBase"
                    as={ShieldFill}
                    style={{ paddingRight: space2 }}
                  />
                  <Paragraph color="colorBlackBase">
                    {translation.secureConnection}
                  </Paragraph>
                </Flex>
              </Box>
            )}
            {POSSIBLE_LANGUAGES[MH_BRAND]?.length > 1 && (
              <Box>
                <SelectWithouthBorder
                  value={lang}
                  id="language"
                  labelHidden={true}
                  onChange={setLanguage}
                  disabled={isLangSelectDisabled()}
                >
                  {POSSIBLE_LANGUAGES[MH_BRAND].map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </SelectWithouthBorder>
              </Box>
            )}
          </Flex>
        </Flex>
      </Wrapper>
    </Box>
  );
};

export default TopBar;
