import styled from 'styled-components';
import {
  colorPrimaryBase,
  colorTertiary70,
} from '@mediahuis/chameleon-theme-wl';
import { Box, Caption } from '@mediahuis/chameleon-react';

const Label = styled(Box)`
  display: inline-block;
  position: relative;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  &:after {
    content: ' ';
    background-color: ${props =>
      props.iseven ? colorPrimaryBase : colorTertiary70};
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 4px;
    top: 0;
    right: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  && {
    margin-right: -4px;
  }
`;

const InlineLabel = ({ text }) => {
  const labels = text.split(',');

  return (
    <div>
      {labels.map((label, index) => {
        const isEven = index % 2 === 0;
        return (
          <Label
            py={2}
            px={3}
            key={label}
            bgColor={isEven ? 'colorPrimaryBase' : 'colorTertiary70'}
            iseven={isEven ? 1 : 0}
          >
            <Caption
              level={2}
              color={isEven ? 'colorTextPrimaryBase' : 'colorWhiteBase'}
            >
              {label}
            </Caption>
          </Label>
        );
      })}
    </div>
  );
};

export default InlineLabel;
