const BRANDS = {
  DEMO: 'demo',
};

const MH_BRAND = BRANDS.DEMO;
const MH_ENV = 'production';

const HTML_CONTENT_LOCATIONS = {
  GIFT: 'Gift',
  OFFER: 'Offer',
  VOUCHER: 'Voucher',
  VOUCHER_TEMPLATE: 'VoucherTemplate',
};

const MH_THEMES = {
  [BRANDS.DEMO]: 'hmc-nhd',
};

const LANG = {
  NL: 'nl',
  FR: 'fr',
  EN: 'en',
  DE: 'de',
  PT: 'pt',
};

const POSSIBLE_LANGUAGES = {
  [BRANDS.DEMO]: [{ value: LANG.NL, label: 'NL' }],
};

const ENVS = {
  test: 'test',
  preview: 'preview',
  production: 'production',
};

const TENANTS = {
  [BRANDS.DEMO]: 'mediahuis.com',
};

const GROUPS = {
  BE: 'BE',
  DL: 'DL',
  LUX: 'LUX',
  AA: 'AA',
};

const MH_GROUPS = {
  [BRANDS.DEMO]: GROUPS.BE,
};

const SITE_URLS = {
  [BRANDS.DEMO]: 'noordhollandsdagblad.nl',
};

const NEWS_APP_URLS = {
  [BRANDS.DEMO]: '',
};

const CDN_BRAND_DOMAINS = {
  [BRANDS.DEMO]: 'gva.be',
};

const SITE_NAMES = {
  [BRANDS.DEMO]: 'Noord Hollands Dagblad',
};

const MOLLIE_IDS = {
  [BRANDS.DEMO]: '',
};

const URL_PREFIXES = {
  [ENVS.test]: 'test',
  [ENVS.preview]: 'preview',
  [ENVS.production]: 'www',
};

const INSIGHT_KEYS = {
  [ENVS.test]: '59525685-ea32-4aa4-964b-1d74a643f194',
  [ENVS.preview]: 'ddfcaece-c181-4f71-ad83-a518e1cf90af',
  [ENVS.production]: '910f4a4b-1374-48c2-909f-e1c70469bca8',
};

const API_PREFIXES = {
  [ENVS.test]: 'test',
  [ENVS.preview]: 'preview',
  [ENVS.production]: 'demo',
};

const SSO_TOKENS = {
  [ENVS.test]: '_D',
  [ENVS.preview]: '_A',
  [ENVS.production]: '',
};

const API_ADDRESS_SERVICES = {
  [ENVS.test]: 'https://testaddressservice.mediahuis.be',
  [ENVS.preview]: 'https://previewaddressservice.mediahuis.be',
  [ENVS.production]: 'https://testaddressservice.mediahuis.be',
};

const TRACKING_ENVS = {
  [GROUPS.BE]: 'mhbe',
  [GROUPS.DL]: 'mhnl',
  [GROUPS.LUX]: 'mhlu',
  [GROUPS.AA]: 'mhaa',
};

const SECURE_FIELDS_URLS = {
  [ENVS.test]:
    'https://pay.sandbox.datatrans.com/upp/payment/js/secure-fields-2.0.0.min.js',
  [ENVS.preview]:
    'https://pay.sandbox.datatrans.com/upp/payment/js/secure-fields-2.0.0.min.js',
  [ENVS.production]:
    'https://pay.datatrans.com/upp/payment/js/secure-fields-2.0.0.min.js',
};

const SITE_URL = SITE_URLS[MH_BRAND];
const CDN_BRAND_DOMAIN = CDN_BRAND_DOMAINS[MH_BRAND];
const API_PREFIX = API_PREFIXES[MH_ENV];
const INSIGHT_KEY = INSIGHT_KEYS[MH_ENV];
const MH_THEME = MH_THEMES[MH_BRAND];
const URL_PREFIX = URL_PREFIXES[MH_ENV];
const MH_GROUP = MH_GROUPS[MH_BRAND];
const TRACKING_ENV = TRACKING_ENVS[MH_GROUP];
const NEWS_APP_URL = NEWS_APP_URLS[MH_BRAND];

const SITE_CONFIG = {
  [BRANDS.DEMO]: {
    code: 'demo',
    brand: 'demo',
    title: 'Noord Hollands Dagblad - Alle Formules en prijzen',
    description: 'Vergelijk formules en kies voor papier en/of digitaal',
    ogDescription: 'Vergelijk formules en kies voor papier en/of digitaal',
    ogImage: '',
    ogSiteName: 'Noord Hollands Dagblad',
    ogTitle: 'Noord Hollands Dagblad - Alle Formules en prijzen',
    ogUrl: `https://${API_PREFIX}aboshop.${SITE_URLS[BRANDS.HUB]}`,
  },
};

const SITE_CONFIG_BRANDED = SITE_CONFIG[MH_BRAND];

const GLOBALS = {
  ACCOUNT_LOG_OFF: `http://${URL_PREFIX}.mediahuis.com/account/logoff`,
  API_ABOPROPOSAL_SERVICE: `https://${API_PREFIX}aboproposalservice.mediahuis.com/api`,
  API_ACCOUNT_SERVICE: `https://${API_PREFIX}accountservice.mediahuis.com/api`,
  API_ACCOUNT_MANAGEMENT_SERVICE: `https://${API_PREFIX}accountmanagement.mediahuis.com/api`,
  API_ADDRESS_SERVICE: API_ADDRESS_SERVICES[MH_ENV],
  API_AFFILIATE_SERVICE: `https://${API_PREFIX}affiliateservice.${TENANTS[MH_BRAND]}/api`,
  API_CONTENT_SERVICE: `https://${API_PREFIX}contentservice.${TENANTS[MH_BRAND]}/api`,
  API_DEFAULT_VALIDATOR_SERVICE: `https://${API_PREFIX}defaultvalidator.${TENANTS[MH_BRAND]}/api`,
  API_FILE_VALIDATOR_SERVICE: `https://${API_PREFIX}filevalidator.${TENANTS[MH_BRAND]}/api`,
  API_LOCATION_VALIDATOR_SERVICE: `https://${API_PREFIX}locationvalidator.${TENANTS[MH_BRAND]}/api`,
  API_LOGIN_VALIDATOR_SERVICE: `https://${API_PREFIX}loginvalidator.mediahuis.com/api`,
  API_OFFER_SERVICE: `https://${API_PREFIX}offerservice.${TENANTS[MH_BRAND]}/api`,
  API_OFFER_RENEWAL_SERVICE: `https://${API_PREFIX}offerrenewalservice.mediahuis.be/api`,
  API_ORDER_SERVICE: `https://${API_PREFIX}orderservice.${TENANTS[MH_BRAND]}/api`,
  API_ORDER_SETTLE_SERVICE: `https://${API_PREFIX}ordersettleservice.mediahuis.be/api`,
  API_PAYMENT_SERVICE: `https://${API_PREFIX}paymentservice.${TENANTS[MH_BRAND]}/api`,
  API_PICKUP_LOCATION_SERVICE: `https://${API_PREFIX}pickuplocationservice.mediahuis.be/api`,
  API_PRECONDITION_VALIDATOR_SERVICE: `https://${API_PREFIX}preconditionvalidator.mediahuis.com/api`,
  API_PREFIX,
  API_SUBSCRIBER_FILE_SHARE_SERVICE: `https://${API_PREFIX}subscriberfileshare.${TENANTS[MH_BRAND]}/api`,
  API_SUBSCRIBER_PDF_SERVICE: `https://${API_PREFIX}subscriberpdfservice.${TENANTS[MH_BRAND]}/api`,
  API_SUBSCRIPTION_SCOPE_SERVICE: `https://${API_PREFIX}subscriptionscopeservice.${TENANTS[MH_BRAND]}/api`,
  API_TEXTFIELD_VALIDATOR_SERVICE: `https://${API_PREFIX}textfieldvalidator.${TENANTS[MH_BRAND]}/api`,
  API_VOUCHER_SERVICE: `https://${API_PREFIX}voucherservice.${TENANTS[MH_BRAND]}/api`,
  BRANDS,
  CDN_PATH: `https://teststatic.${CDN_BRAND_DOMAIN}`,
  CUSTOMER_SERVICE_URL: `https://${API_PREFIX}klantenservice.mediahuis.com`,
  ENVS,
  GROUPS,
  HTML_CONTENT_LOCATIONS,
  INSIGHT_KEY,
  KBO_SERVICE: `https://${API_PREFIX}cbeservice.mediahuis.be/api`,
  LANG,
  MH_BRAND,
  MH_ENV,
  MH_GROUP,
  MH_THEME,
  MOLLIE_ID: MOLLIE_IDS[MH_BRAND],
  MOLLIE_TEST: MH_ENV !== 'production',
  NEWS_APP_URL,
  POSSIBLE_LANGUAGES,
  SECURE_FIELDS_URL: SECURE_FIELDS_URLS[MH_ENV],
  SITE_NAMES,
  SITE_NAME: SITE_NAMES[MH_BRAND],
  SITE_URL,
  SSO_TOKEN: SSO_TOKENS[MH_ENV],
  TRACKING_ENV,
  UMBRACO_URL: `https://umbraco.mediahuislimburg.nl/umbraco/api/forms`,
  URL_PREFIX,
};

module.exports = {
  API_PREFIXES,
  BRANDS,
  ENVS,
  GLOBALS,
  GROUPS,
  MH_BRAND,
  MH_ENV,
  MH_GROUP,
  MH_THEME,
  MOLLIE_IDS,
  SITE_CONFIG,
  SITE_CONFIG_BRANDED,
  SITE_NAMES,
  SITE_URLS,
  SSO_TOKENS,
  URL_PREFIXES,
};
