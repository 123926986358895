/* eslint-disable camelcase */
import { UTM_KEY, NEWS_APP } from '~/constants';

const urlParametersKeys = [
  'utm_artid',
  'utm_medium',
  'utm_source',
  'utm_term',
  'utm_content',
  'utm_campaign',
  'ref_utm_artid',
  'ref_utm_medium',
  'ref_utm_source',
  'ref_utm_term',
  'ref_utm_content',
  'ref_utm_campaign',
  'utm_internal',
  'utm_internal_medium',
  'utm_internal_source',
  'utm_internal_term',
  'utm_internal_content',
  'utm_internal_campaign',
  'ref_utm_internal',
  'ref_utm_internal_medium',
  'ref_utm_internal_source',
  'ref_utm_internal_term',
  'ref_utm_internal_content',
  'ref_utm_internal_campaign',
  'sub_id',
];

const compareUtmParams = (requestUtms, localUtms) =>
  urlParametersKeys.every(key => requestUtms[key] === localUtms[key]);

export const checkUtmParameters = dataObject => {
  const localUtmObject = JSON.parse(sessionStorage.getItem(UTM_KEY) || '{}');

  const utmCheck = compareUtmParams(dataObject, localUtmObject || {});

  if (!localUtmObject || (localUtmObject && !utmCheck)) {
    const processedObject = {};
    for (const key of urlParametersKeys) {
      if (dataObject[key] !== undefined) {
        processedObject[key] = dataObject[key] || undefined;
      } else {
        processedObject[key] = localUtmObject[key];
      }
    }

    sessionStorage.setItem(UTM_KEY, JSON.stringify(processedObject));
  }
};

export const parseUtmParameters = () =>
  JSON.parse(sessionStorage.getItem(UTM_KEY) || '{}');

export const isNewsApp = () => {
  const utms = parseUtmParameters();
  const utmMedium = Object.prototype.hasOwnProperty.call(utms, 'utm_medium')
    ? utms.utm_medium
    : null;
  return utmMedium === NEWS_APP;
};
export const utmTerm = () => {
  const utms = parseUtmParameters();
  return Object.prototype.hasOwnProperty.call(utms, 'utm_term')
    ? utms.utm_term
    : null;
};
