export const LOGIN = 'Login';
export const BOOL = 'Boolean';
export const MAIL = 'Email';
export const ADDRESS_AREA = 'AddressArea';
export const INVOICE = 'InvoiceDetails';
export const CHOICE = 'Choice';
export const DATE = 'Date';
export const PAYMENT = 'Payment';
export const FILE = 'File';
export const PRECONDITION = 'Preconditions';
export const TEXT_FIELD = 'Textfield';
export const VOUCHER = 'Voucher';

export const VALIDATION_KEYS = {
  [BOOL]: 'boolValidation',
  [DATE]: 'dateValidation',
  [FILE]: 'fileValidation',
  [MAIL]: 'emailValidation',
  [LOGIN]: 'loginValidation',
  [CHOICE]: 'choiceValidation',
  [INVOICE]: 'invoiceValidation',
  [PAYMENT]: 'paymentValidation',
  [TEXT_FIELD]: 'textFieldValidation',
  [ADDRESS_AREA]: 'addressAreaValidation',
  [PRECONDITION]: 'preconditionValidation',
  [VOUCHER]: 'voucherValidation',
};

export const INCENTIVE_PHONE_NUMBER = 'incentivePhoneNumber';
export const PHONE_NUMBER = 'phoneNumber';
export const PRIMARY_AGREEMENT_ID = 'primaryAgreementId';

export const TEXT_FIELD_KEYS = {
  INCENTIVE_PHONE_NUMBER,
  PHONE_NUMBER,
  PRIMARY_AGREEMENT_ID,
};
