import styled from 'styled-components';
import { Box } from '@mediahuis/chameleon-react';

const AboBox = styled(Box)`
  width: 100%;
  max-width: 1280px;
  && {
    margin: 0 auto;
  }
  p img {
    vertical-align: text-bottom;
  }
`;

const Wrapper = ({ children }) => <AboBox px={5}>{children}</AboBox>;

export default Wrapper;
