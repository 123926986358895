import { getI18n } from 'react-i18next';
import { Caption } from '@mediahuis/chameleon-react';
import language from './language';

const AboPolicyLink = () => {
  const { language: selectedLanguage } = getI18n();
  const { AboPolicy } = language[selectedLanguage];
  return (
    <Caption>
      <AboPolicy />
    </Caption>
  );
};

export default AboPolicyLink;
