const formatHouseNumber = value => {
  const hasAlphaNumerical = value.match(/[a-z]/g);
  const validHouseNumber = value.match(/\d+[a-zA-Z]/g);

  return {
    isValidHouseNumber:
      !hasAlphaNumerical ||
      (validHouseNumber && hasAlphaNumerical?.length === 1),
    formattedHouseNumber:
      validHouseNumber && MH_GROUP !== 'LUX'
        ? validHouseNumber[0]
        : value.replace(/[^-0-9]/g, ''),
  };
};

export default formatHouseNumber;
